:root {
  --bg: #fafcff;
  --bg2: hsl(0deg, 10%, 80%, 0.35);
  --fontfam1: "Fira Sans Extra Condensed", Arial, Helvetica, sans-serif;
  --fontfam2: "Montserrat", sans-serif;
  --fontfam3: "Ephesis", cursive;
  --fontcol1: #f8f2fe;
  --fontcol1: #010128;
  --fontcol2: #0100ff;
  --ctacol: #ff0e55;
}

.App {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  color: var(--fontcol1);
  font-family: Arial, Helvetica, sans-serif;
}

.banner {
  position: fixed;
  width: 100vw;
  height: 10vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: hsla(0, 0%, 0%, 0.6);
  background: linear-gradient(hsla(0, 10%, 80%, 0.25) 50%, hsla(0, 10%, 80%, 0.2) 95%, hsla(0, 10%, 80%, 0.35));
  backdrop-filter: blur(10px);
  z-index: 20;
  font-family: var(--fontfam1);
  text-transform: capitalize;
}

.logo {
  cursor: pointer;
  height: 7.5vh;
  object-fit: contain;
}

.navbar {
  display: flex;
  justify-content: space-evenly;
  width: min(75%, 800px);
}
.navbar .nav-items {
  position: relative;
  font-size: max(20px, 2rem);
  cursor: pointer;
  transition: color 0.25s ease-in-out;
}
.navbar .nav-items::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 2px;
  background: var(--ctacol);
  transition: 0.25s transform ease-in-out;
  transform: scaleX(0);
  transform-origin: left;
  pointer-events: none;
}
.navbar .nav-items:hover::after {
  transform: scaleX(1);
}
.navbar .nav-items.active::after {
  transform: scaleX(1);
}

.language-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.language-toggle .language-toggle-img {
  height: 2rem;
  cursor: pointer;
}
.language-toggle .language-toggle-selector-container {
  position: relative;
  cursor: pointer;
  width: 2.5rem;
  height: 1.25rem;
  border-radius: 999px;
  border: 2px solid var(--fontcol1);
  margin: 0 1vmin;
}
.language-toggle .language-toggle-selector-container .language-toggle-selector {
  pointer-events: none;
  position: absolute;
  height: 1.25rem;
  width: 1.25rem;
  background: var(--fontcol1);
  transition: transform 0.25s ease-in-out;
  border-radius: 999px;
}
.language-toggle .language-toggle-selector-container .english-selected {
  transform: translateX(100%);
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
}
.arrow div {
  position: relative;
  height: 2.5px;
  width: 3rem;
  background: var(--ctacol);
  border-radius: 25px;
  transform-origin: var(--arrow-transform-origin);
  transition: 0.25s transform ease-in-out, 0.5s background ease-in-out;
}
.arrow div::after, .arrow div::before {
  content: "";
  position: absolute;
  background: var(--ctacol);
  height: 2.5px;
  width: 100%;
  border-radius: 25px;
  transition: 0.25s transform ease-in-out, 0.5s background ease-in-out;
  transform-origin: var(--arrow-transform-origin);
}
.arrow:hover > div::after {
  transform: scaleX(0.25) rotate(7.5deg);
  background: var(--ctacol);
}
.arrow:hover > div::before {
  transform: scaleX(0.25) rotate(-7.5deg);
  background: var(--ctacol);
}
.arrow[data-direction=left] {
  --arrow-transform-origin: left;
  left: 12px;
}
.arrow[data-direction=right] {
  --arrow-transform-origin: right;
  right: 12px;
}

.slide-container {
  position: relative;
  width: 400vw;
  height: 100vh;
  display: flex;
  transition: transform 0.5s ease-in-out;
  background: var(--bg);
}

.slide-wrapper {
  display: grid;
  place-content: center;
  position: relative;
  top: 10vh;
  width: 100vw;
  height: 90vh;
}
.slide-wrapper > div {
  display: grid;
  width: inherit;
  height: inherit;
}

.section-title {
  display: none;
}