@font-face {
    font-family: 'Ephesis';
    src: url('./fonts/Ephesis-Regular.eot');
    src: local('Ephesis Regular'), local('Ephesis-Regular'),
        url('./fonts/Ephesis-Regular.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Ephesis-Regular.woff2') format('woff2'),
        url('./fonts/Ephesis-Regular.woff') format('woff'),
        url('./fonts/Ephesis-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'arial black';
    src: url('./fonts/Arial-Black.eot');
    src: local('Arial Black'), local('Arial-Black'),
        url('./fonts/Arial-Black.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Arial-Black.woff2') format('woff2'),
        url('./fonts/Arial-Black.woff') format('woff'),
        url('./fonts/Arial-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('./fonts/Ubuntu-Regular.eot');
    src: local('Ubuntu Regular'), local('Ubuntu-Regular'),
        url('./fonts/Ubuntu-Regular.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Ubuntu-Regular.woff2') format('woff2'),
        url('./fonts/Ubuntu-Regular.woff') format('woff'),
        url('./fonts/Ubuntu-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fira Sans Extra Condensed';
    src: url('./fonts/FiraSansExtraCondensed-MediumItalic.eot');
    src: url('./fonts/FiraSansExtraCondensed-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/FiraSansExtraCondensed-MediumItalic.woff2') format('woff2'),
        url('./fonts/FiraSansExtraCondensed-MediumItalic.woff') format('woff'),
        url('./fonts/FiraSansExtraCondensed-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}



/* loader */

.page-loader {
	height: 100vh;
	width: 100vw;
	background: #222;
}


.loader-container {	
	height: 50vmin;
	width: 50vmin;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.ball {
	position: absolute;
	top: 25vh;
	height: 10vmin;
	width: 10vmin;
	background: whitesmoke;
	border-radius: 100%;
	transform-origin: -10vmin center;
}

.ball-2 {
	right: 12.5vmin;
	animation: linear 3s infinite sheep1;
}

.ball-1 {
	right: -2.5vmin;
	animation: linear 3s infinite sheep2;
}

@keyframes sheep1 {
	33% {
		transform: rotate(0deg);
	}

	66% {
		transform: rotate(-180deg);
	}

	100% {
		transform: rotate(-180deg) translate(-30vmin);
	}
}

@keyframes sheep2 {
	33%, 66% {
		transform: rotate(-180deg);
	}

	100% {
		transform: rotate(-180deg) translate(-30vmin);
	}

}


	/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

	html, body, div, span, applet, object, iframe,
	h1, h2, h3, h4, h5, h6, p, blockquote, pre,
	a, abbr, acronym, address, big, cite, code,
	del, dfn, em, img, ins, kbd, q, s, samp,
	small, strike, strong, sub, sup, tt, var,
	b, u, i, center,
	dl, dt, dd, ol, ul, li,
	fieldset, form, label, legend,
	table, caption, tbody, tfoot, thead, tr, th, td,
	article, aside, canvas, details, embed,
	figure, figcaption, footer, header, hgroup,
	menu, nav, output, ruby, section, summary,
	time, mark, audio, video {
		margin: 0;
		padding: 0;
		border: 0;
		font-size: 100%;
		font: inherit;
		vertical-align: baseline;
	}

	/* HTML5 display-role reset for older browsers */
	article, aside, details, figcaption, figure,
	footer, header, hgroup, menu, nav, section {
		display: block;
	}

	body {
		line-height: 1;
	}

	ol, ul {
		list-style: none;
	}

	blockquote, q {
		quotes: none;
	}

	blockquote:before, blockquote:after,
	q:before, q:after {
		content: '';
		content: none;
	}

	table {
		border-collapse: collapse;
		border-spacing: 0;
	}

	/* disable links default style*/
	a, a:link, a:visited, a:focus, a:hover, a:active {
		text-decoration: none;
		color: white;
	}