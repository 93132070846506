/* ======= LANDSCAPE ====== */
@media (orientation: landscape) and (max-width: 1680px) {
  /* GLOBAL */
  :root {
    font-size: 14px;
  }
}
@media (orientation: landscape) and (max-width: 1366px) {
  /* GLOBAL */
  :root {
    font-size: 12px;
  }
  /* HOME SLIDE */
}
@media (orientation: landscape) and (max-width: 1024px) {
  /* GLOBAL */
  :root {
    font-size: 10px;
  }
  /* HOME SLIDE */
}
@media (orientation: landscape) and (max-width: 900px) {
  /* GLOBAL */
  :root {
    font-size: 8px;
  }
  /* HOME SLIDE */
}
@media (orientation: landscape) and (max-width: 812px) {
  :root {
    font-size: 7px;
  }
  /* GLOBAL */
  /* HOME SLIDE */
}
/* WORK SLIDE  */
@media (orientation: landscape) and (max-height: 500px) {
  .video-infos-container {
    display: flex;
    align-items: center;
    line-height: 0;
  }
  .video-infos-container > div {
    margin: 0;
  }
  .video-descr {
    display: none;
  }
}
/* ======= PORTRAIT ======= */
@media (orientation: portrait) {
  :root {
    font-size: 6px;
    --avatar-length-unit: min(0.29vh, 0.67vw);
  }
  /* GLOBAL */
  .banner {
    --clippy: 0%;
    width: 100vw;
    overflow: hidden;
    height: 100vh;
    clip-path: circle(var(--clippy) at 3rem 3rem);
    flex-direction: column-reverse;
    justify-content: space-evenly;
    background: linear-gradient(90deg, hsla(180, 0%, 80%, 0.95), hsla(180, 0%, 80%, 0.65) 15%, hsla(180, 0%, 80%, 0.75) 50%, hsla(180, 0%, 80%, 0.65) 85%, hsla(180, 0%, 80%, 0.95));
    transition: clip-path 0.25s ease-in-out;
  }
  .banner .navbar {
    width: 50vw;
    height: 40vh;
    flex-direction: column;
    align-items: center;
  }
  .banner .navbar .nav-items {
    width: fit-content;
  }
  .banner .social-network {
    width: 75vw;
    font-size: 5rem;
    padding: 0;
    margin-inline: auto;
    height: 20vh;
    display: grid;
    grid-template: repeat(2, 1fr)/repeat(2, 1fr);
  }
  .banner .social-network a {
    justify-self: center;
  }
  .banner .logo {
    font-size: 10vw;
  }
  .banner .language-toggle {
    width: auto;
    font-size: 2rem;
  }
  .banner .language-toggle .language-toggle-img {
    height: 5rem;
  }
  .banner .language-toggle .language-toggle-selector-container {
    width: 5rem;
    height: 2.5rem;
    border-radius: 999px;
    border: 2px solid var(--fontcol1);
    margin: 0 1vmin;
  }
  .banner .language-toggle .language-toggle-selector-container .language-toggle-selector {
    height: 2.5rem;
    width: 2.5rem;
  }
  .banner.openBanner {
    --clippy: 150%;
  }
  .slide-container {
    position: relative;
    width: 100vw;
    height: 400vh;
    flex-direction: column;
    transition: transform 0.5s ease-in-out;
    --bg-gradient-angle: 180deg;
  }
  .slide-wrapper {
    top: 0;
    height: 100vh;
  }
  .slide-wrapper > div {
    height: 95vh;
  }
  /* HOME SLIDE */
  .home-wrapper {
    grid-template-areas: "name name" "avatar avatar";
  }
  .home-wrapper .social-network {
    display: none;
  }
  .home-wrapper .introducing-myself {
    margin: 0;
    align-self: flex-start;
    margin-top: 10vh;
  }
  .home-wrapper .introducing-myself .my-name {
    font-size: min(10vh, 12.5vw);
  }
  .home-wrapper .introducing-myself .my-role {
    font-size: min(5vh, 7vw);
  }
  .home-wrapper .status-container {
    grid-area: name;
    height: max(12.5vh, 17.5vw);
    margin-top: max(25vh, 35vw);
    margin-left: 0;
  }
  .home-wrapper .status-container .status {
    font-size: min(4.75vh, 6.5vw);
  }
  .home-wrapper .status-container .reload-status {
    font-size: 3rem;
  }
  .home-wrapper .status-container .reload-status:hover {
    font-size: 3.25rem;
  }
  .home-wrapper .my-avatar {
    bottom: 0;
    right: 0;
    width: min(35vh, 80vw);
  }
  .home-wrapper .my-avatar .pull-me-container {
    height: 12rem;
  }
  .home-wrapper .my-avatar .pull-me {
    height: 5rem;
    width: 5.5rem;
    font-size: 3rem;
  }
  .home-wrapper .chest-container {
    height: min(20.1vh, 27vw);
    width: min(16.5vh, 22.5vw);
    justify-self: flex-start;
    left: calc(25% - 7.5vh);
    bottom: 20%;
  }
  .home-wrapper .accessory-container1 {
    left: 22%;
    bottom: 43%;
  }
  .home-wrapper .accessory-container2 {
    left: 17%;
    bottom: 34%;
  }
  .home-wrapper .accessory-container3 {
    left: 32%;
    bottom: 38%;
  }
  .home-wrapper .accessory-container4 {
    left: 22%;
    bottom: 41%;
  }
  .home-wrapper .accessory-container5 {
    left: 18%;
    bottom: 43%;
  }
  .home-wrapper .accessory-container6 {
    left: 21%;
    bottom: 39%;
  }
  .home-wrapper .accessory-container7 {
    left: 21%;
    bottom: 45%;
  }
  .home-wrapper .accessory-container8 {
    left: 17%;
    bottom: 43%;
  }
  .home-wrapper .accessory-container9 {
    left: 26%;
    bottom: 43%;
  }
  .home-wrapper .accessory-container10 {
    left: 24%;
    bottom: 41%;
  }
  .home-wrapper .accessory-container11 {
    left: 30%;
    bottom: 40%;
  }
  .home-wrapper .accessory-hidden {
    left: calc(25% - 7.5vh);
    bottom: 20%;
  }
  .remind-cta {
    top: -2.5vh;
    width: 5vh;
    height: 5vh;
  }
  /* WORK SLIDE */
  .work-wrapper {
    grid-template: repeat(11, 1fr)/1fr;
    width: 80vw;
    grid-template-areas: "section" "video" "video" "video" "video" "controls" "thumbnail" "thumbnail" "infos" "infos" "infos";
  }
  .work-wrapper > * {
    margin: 0;
  }
  .section-title {
    font-family: var(--fontfam1);
    display: block;
    width: 100vw;
    display: grid;
    place-content: center;
    font-size: 10vw;
    height: 7rem;
  }
  .video-player-container {
    justify-self: center;
    width: 100%;
  }
  .video-controls {
    display: flex;
    position: relative;
    justify-self: center;
    align-self: center;
    grid-area: controls;
    width: calc(100% - 4rem);
    background: var(--bg);
    border: none;
    opacity: 1;
  }
  .video-thumb-container {
    grid-area: thumbnail;
    width: calc(100% - 4rem);
    justify-self: center;
    align-self: center;
    height: calc(100% - 2rem);
    overflow-y: hidden;
    overflow-x: scroll;
    display: flex;
  }
  .video-thumb-container .video-thumb {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
  .video-thumb-container .video-thumb .video-thumb-rank {
    position: absolute;
    font-size: 6rem;
  }
  .video-thumb-container .video-thumb .video-thumb-img {
    height: 95%;
    width: auto;
    object-fit: contain;
    cursor: pointer;
    border-radius: 0.2rem;
    grid-area: video;
    border: 1px solid grey;
  }
  .video-thumb-container .video-thumb .video-thumb-name {
    display: none;
  }
  .video-thumb-container .video-thumb-played .video-thumb-img {
    filter: opacity(50%);
  }
  .video-thumb-container::-webkit-scrollbar {
    width: auto;
    height: 2rem;
  }
  .video-infos-container {
    grid-area: infos;
    height: 95%;
    justify-self: center;
    align-self: flex-start;
  }
  .video-infos-container .video-descr {
    font-size: 2rem;
  }
  .video-infos-container {
    height: 95%;
    justify-self: center;
    align-self: flex-start;
    grid-template-columns: 1fr;
    grid-template-areas: "name" "descr" "links";
  }
  .video-infos-container .video-descr {
    font-size: 2rem;
  }
  .video-links {
    justify-content: center;
  }
  .video-links a {
    margin-right: 0;
  }
  .video-infos-container .video-descr {
    font-size: 2rem;
  }
  .video-links {
    justify-content: center;
  }
  .video-links a {
    margin-right: 0;
  }
  /* SKILLS SLIDE */
  .skills-wrapper {
    grid-template: 1fr 1.5fr/1fr;
    grid-template-areas: "bubble" "avatar";
  }
  .skills-wrapper .section-title {
    position: absolute;
  }
  .intro-of-myself {
    font-size: 4vw;
  }
  .avatar-at-work {
    height: auto;
    max-width: 80vw;
    pointer-events: none;
    align-self: flex-end;
    justify-self: flex-end;
  }
  .hit-zone {
    justify-self: center;
  }
  .skills-container {
    grid-area: avatar;
    position: relative;
    width: 40vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 0;
    padding: 0 1.5rem;
    font-size: 3rem;
  }
  .skills-container .my-skill {
    align-self: flex-start;
  }
  .my-resume {
    position: absolute;
    top: -12.5%;
    height: 7.5rem;
    width: calc(100vw - 18rem);
    margin: 0 5rem;
  }
  .video-modal {
    height: 100vh;
    --my-video-width: 100vw;
    --my-video-height: 55vw;
  }
  .video-modal .video-modal-player {
    height: var(--my-video-height);
    width: var(--my-video-width);
    top: 50vh;
    left: 0;
    transform: translateY(-50%);
    border-radius: 0;
  }
  .video-modal .my-video-modal {
    height: var(--my-video-height);
    width: var(--my-video-width);
  }
  .video-modal .exit-modal {
    font-size: 3.5rem;
    right: 2rem;
    top: 2rem;
  }
  /* CONTACT SLIDE */
  .contact-wrapper .section-title {
    position: absolute;
  }
  .postcard-container {
    position: absolute;
    top: 52.5vh;
    width: 90vw;
    height: 85vh;
    font-size: 5rem;
    justify-content: center;
    align-items: center;
    background: none;
  }
  .postcard-left-input input,
  .postcard-left-input textarea {
    font-size: 5rem;
  }
  .postcard-left-input label {
    font-size: 2rem;
  }
  input:focus ~ label,
  textarea:focus ~ label,
  input:valid ~ label,
  textarea:valid ~ label {
    font-size: 1.5rem;
  }
  .postcard-left-submit-btn {
    font-size: 3rem;
  }
  /* ------------- POSTCARD ------------- */
  .postcard {
    width: inherit;
    height: inherit;
    border: none;
  }
  .postcard-left {
    width: 100%;
    border-right: none;
  }
  .postcard-left-message {
    height: 45vh;
  }
  textarea {
    height: 39.5vh;
  }
  .postcard-left-submit-btn {
    width: 75%;
    align-self: center;
  }
  .postcard-right {
    display: none;
  }
  /* ------------- ENVELOPPE ------------- */
  .enveloppe {
    position: absolute;
    left: 5vw;
    bottom: 2.5vh;
    width: 90vw;
    height: 90vh;
  }
  .enveloppe::before {
    /* languette */
    content: "";
    width: 90vw;
    height: 90vh;
    top: -36vh;
    transform-origin: 0 36vh;
    background: #000;
  }
  .enveloppe::after {
    content: "";
    width: 90vw;
    height: 90vh;
    background: #000;
  }
  /* ------------- MESSAGE ENVOYE ------------- */
  .message-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 8vw;
    width: 65vw;
    font-family: var(--font-2);
    color: var(--fontcol1);
    font-size: 7.5vw;
  }
}
@media (orientation: portrait) and (min-height: 800px) {
  :root {
    font-size: 7px;
  }
}
@media (orientation: portrait) and (min-height: 1000px) {
  :root {
    font-size: 12px;
  }
}