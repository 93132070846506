.contact-wrapper {
  place-content: center;
}

.postcard-container {
  position: absolute;
  top: 45vh;
  left: 50vw;
  width: 100vh;
  height: 75vh;
  transform: translate(-50%, -50%);
  font-family: var(--fontfam3);
  font-size: 2.5rem;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: hsl(0, 0%, 25%);
  display: flex;
  justify-content: center;
  align-items: center;
  background: repeating-linear-gradient(315deg, var(--bg2), var(--bg2) 2%, var(--fontcol2) 2%, var(--fontcol2) 3%, var(--bg2) 3%, var(--bg2) 5%, var(--ctacol) 5%, var(--ctacol) 6%);
  border: 1px solid hsl(0, 0%, 25%);
  border-radius: 5px;
  transition: opacity 0.5s ease-in-out;
}

.animation-container {
  display: none;
  z-index: 20;
}

/* ------------- POSTCARD ------------- */
.postcard {
  background: #fff;
  width: 95vh;
  height: 70vh;
  border: 1px solid hsl(0, 0%, 25%);
  display: flex;
  border-radius: 5px;
}

.postcard-left,
.postcard-right {
  padding: 2.5%;
  margin-top: 2.5%;
  margin-bottom: 2.5%;
}

.postcard-left {
  width: 60%;
  border-right: 1px solid hsl(0, 0%, 25%);
}

.postcard-left-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.postcard-left-salutation {
  margin-left: 5vw;
}

.postcard-left-input {
  width: 100%;
  margin: 1% 0;
  position: relative;
  border: 1px solid hsl(0, 0%, 75%);
  background: hsl(0, 0%, 97.5%);
  border-radius: 5px;
}

.postcard-left-input input,
.postcard-left-input textarea {
  padding: 5%;
  border: 0;
  width: 90%;
  background: hsl(0, 0%, 97.5%);
  font-family: var(--fontfam3);
  font-weight: bold;
  font-size: 2.5rem;
}

.postcard-left-input label {
  position: absolute;
  left: 0;
  padding: 0.5vw;
  font-family: var(--fontfam2);
  font-weight: 400;
  font-size: 1rem;
  color: hsl(0, 0%, 25%);
  transition: padding 0.25s ease, font-size 0.25s ease;
  pointer-events: none;
}

input:focus ~ label,
textarea:focus ~ label,
input:valid ~ label,
textarea:valid ~ label {
  font-size: 0.75rem;
  padding: 0;
}

.postcard-left-message {
  height: 25vh;
}

textarea {
  height: calc(20vh - 2px);
}

.postcard-left-submit-btn {
  height: 5vh;
  width: max(15rem, 40%);
  align-self: center;
  border-radius: 5px;
  border: 1px solid hsl(0, 0%, 75%);
  background: hsl(0, 0%, 97.5%);
  font-size: 1.5rem;
  color: hsl(0, 0%, 25%);
  cursor: pointer;
  margin-top: 1vh;
  transition: background-color 0.25s ease;
}

.postcard-left-submit-btn:hover {
  background-color: var(--ctacol);
}

.postcard-right {
  height: 100%;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.postcard-right-stamp {
  position: absolute;
  top: 5%;
  right: 5%;
  width: 15%;
  height: auto;
}

.postcard-right-stamp img {
  width: 100%;
}

.postcard-right-exp {
  padding-bottom: 5%;
}

.postcard-right-exp > div {
  border-bottom: 1px solid hsl(0, 0%, 25%);
  padding-top: 7.5%;
}

/* ------------- ENVELOPPE ------------- */
.enveloppe {
  position: absolute;
  left: calc(50% - 50vmin);
  bottom: calc(50% - 37.5vmin);
  width: 100vh;
  height: 75vh;
  transform: translate(-50%, -50%);
  background: transparent;
  -webkit-animation: 0.5s ease-out forwards apparition-enveloppe, 0.5s linear 1s forwards reduction, 0.75s linear 1.5s forwards deplacement;
  animation: 0.5s ease-out forwards apparition-enveloppe, 0.5s linear 1s forwards reduction, 0.75s linear 1.5s forwards deplacement;
}

@-webkit-keyframes apparition-enveloppe {
  from {
    transform: translateY(75vmin);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes apparition-enveloppe {
  from {
    transform: translateY(75vmin);
  }
  to {
    transform: translateY(0);
  }
}
@-webkit-keyframes reduction {
  to {
    transform: scale(0.25);
  }
}
@keyframes reduction {
  to {
    transform: scale(0.25);
  }
}
@-webkit-keyframes deplacement {
  to {
    transform: scale(0.025) rotate(-35deg) translateX(2500vw);
    opacity: 0;
  }
}
@keyframes deplacement {
  to {
    transform: scale(0.025) rotate(-35deg) translateX(2500vw);
    opacity: 0;
  }
}
.enveloppe::before {
  /* languette */
  content: "";
  width: 100vh;
  height: 75vh;
  top: -30vh;
  position: absolute;
  background: #e1e1e1;
  -webkit-clip-path: polygon(0% 40%, 50% 0%, 100% 40%);
  clip-path: polygon(0% 40%, 50% 0%, 100% 40%);
  -webkit-animation: 0.5s ease-in-out 0.5s forwards fermeture;
  animation: 0.5s ease-in-out 0.5s forwards fermeture;
  transform-origin: 0 30vh;
}

@-webkit-keyframes fermeture {
  to {
    transform: rotateX(-180deg);
  }
}
@keyframes fermeture {
  to {
    transform: rotateX(-180deg);
  }
}
.enveloppe::after {
  content: "";
  width: 100vh;
  height: 75vh;
  position: absolute;
  background: #ECE9EB;
  -webkit-clip-path: polygon(0 0, 50% 37.5%, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 50% 37.5%, 100% 0, 100% 100%, 0 100%);
}

/* ------------- MESSAGE ENVOYE ------------- */
.message-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: var(--fontfam2);
  color: var(--fontcol1);
  font-size: 7.5rem;
}