.skills-wrapper {
  grid-template: repeat(4, 1fr)/repeat(2, 1fr);
  grid-template-areas: "bubble avatar" "skills avatar" "skills avatar" "skills avatar";
}

.intro-of-myself {
  grid-area: bubble;
  text-align: center;
  font-family: var(--fontfam2);
  line-height: 1.5;
  font-size: 2.5rem;
  align-self: center;
  justify-self: center;
  padding: 2rem;
}

.custom-text-color {
  color: var(--fontcol2);
}

@keyframes remind-click {
  0%, 45%, 55%, 100% {
    transform: none;
  }
  47%, 53% {
    transform: rotate(35deg);
  }
  50% {
    transform: rotate(-5deg);
  }
}
.avatar-at-work {
  grid-area: avatar;
  max-height: 75vh;
  pointer-events: none;
  align-self: flex-end;
}

.hit-zone {
  grid-area: avatar;
  align-self: flex-end;
  width: 50%;
  height: 40%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hit-zone .circle {
  border: 0.5rem solid var(--ctacol);
}

.skills-container {
  grid-area: skills;
  grid-template: repeat(5, 1fr)/repeat(2, 1fr);
  align-items: center;
  justify-items: center;
  display: grid;
  padding: 1.5rem;
  margin: 0 1.5rem 1.5rem 1.5rem;
  font-size: 1.25rem;
}

.my-skill {
  height: 5rem;
  display: flex;
  align-items: center;
}
.my-skill .skill-img {
  height: 4.5rem;
  width: 4.5rem;
  object-fit: contain;
  padding-right: 0.5rem;
  transition: transform 0.5s ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-skill .skill-name {
  font-family: var(--fontfam2);
  transition: opacity 0.25s ease-in-out;
}
.my-skill .hidden-name {
  opacity: 0;
}

.my-skill:last-child {
  grid-column: 1/3;
  align-self: center;
}

.my-resume {
  padding: 0 2.5rem;
  margin-top: 1rem;
  justify-content: center;
}
.my-resume .skill-img {
  padding-right: 0;
}
.my-resume .resume-download {
  display: inline-block;
  font-size: 3.75rem;
  -webkit-animation: 3s linear infinite remind-click;
  animation: 3s linear infinite remind-click;
}
.my-resume a:link,
.my-resume a:visited,
.my-resume a:hover,
.my-resume a:active {
  color: var(--ctacol);
}

.video-modal {
  display: none;
  position: absolute;
  height: 90vh;
  width: 100vw;
  --my-video-width: min(80vw, 125vh);
  --my-video-height: min(44vw, 68.75vh);
}
.video-modal .overlay {
  height: inherit;
  width: inherit;
  background: linear-gradient(var(--bg-gradient-angle), var(--bg-color3), var(--bg-color4));
  opacity: 0.75;
}
.video-modal .video-modal-player {
  position: absolute;
  display: grid;
  place-content: center;
  height: calc(var(--my-video-height) + 2rem);
  width: calc(var(--my-video-width) + 2rem);
  bottom: 10vh;
  left: 50%;
  transform: translateX(-50%);
  background: var(--fontcol1);
  border-radius: 5px;
}
.video-modal .my-video-modal {
  height: var(--my-video-height);
  width: var(--my-video-width);
}
.video-modal .exit-modal {
  position: absolute;
  font-size: 2.5rem;
  right: 3vh;
  top: 3vh;
  color: var(--fontcol1);
  cursor: pointer;
}