.burger-container {
  position: absolute;
  z-index: 30;
  height: 6rem;
  width: 6rem;
  cursor: pointer;
  transition: 0.2s transform 0.05s ease-in;
  margin: 0.5rem 1rem;
}
.burger-container .burger {
  position: absolute;
  left: 0.1rem;
  width: 5.8rem;
  height: 0.65rem;
  background-color: var(--fontcol1);
  border-radius: 999px;
  transition: transform 0.25s ease-in, opacity 0.25s ease-in;
}
.burger-container .burger:nth-child(1) {
  top: 1rem;
}
.burger-container .burger:nth-child(2) {
  top: 2.675rem;
}
.burger-container .burger:nth-child(3) {
  bottom: 1rem;
}
.burger-container .burger-open:nth-child(1) {
  transform: translateY(1.675rem) rotate(45deg);
}
.burger-container .burger-open:nth-child(2) {
  transform: translateX(10%);
  opacity: 0;
}
.burger-container .burger-open:nth-child(3) {
  transform: translateY(-1.675rem) rotate(-45deg);
}

.burger-overlay {
  height: 100vh;
  width: 50vw;
  position: fixed;
  top: 0;
  right: 0;
  display: none;
  z-index: 20;
}